@import 'variables';

$prefix: $antPrefix + -select;

.#{$prefix}-dropdown {
  background-color: $contrastBackground;
  .#{$prefix}-item {
    &:not(.#{$prefix}-item-option-disabled) {
      color: $textColor;
      &.#{$prefix}-item-option-selected {
        color: $primaryColor;
        background-color: $itemHoverBackground;
      }
      &:hover {
        background-color: $itemHoverBackground;
      }
    }
  }
}

%disabled {
  &.ant-select-disabled {
    .ant-select-selector {
      color: $disabledColor;
      background: $bodyBackground;
      border-color: $borderColor;
    }
    .ant-select-arrow {
      color: $disabledColor;
    }
  }
}

.#{$prefix}-multiple {
  &:not(.ant-select-disabled) {
    .ant-select-selector {
      color: $textColor;
      background-color: $componentBackground;
      border-color: $borderColor;
    }

    .#{$prefix}-selection-item {
      background: $itemHoverBackground;
      border-color: $itemHoverBackground;
    }
    .#{$prefix}-selection-item-remove {
      color: $contrastBackground;
      &:hover {
        color: $primaryColor;
      }
    }
  }
  @extend %disabled;
}

.#{$prefix} {
  &.ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      transition: none;
    }
    &:not(.ant-select-disabled) {
      .ant-select-selector {
        color: $textColor;
        background-color: $componentBackground;
        border-color: $borderColor;
      }
      .ant-select-arrow {
        color: $textColor;
      }
      &.ant-select-open,
      &:hover,
      &:focus,
      &:focus-within {
        .ant-select-selector {
          color: $primaryColor;
          border-color: $primaryColor;
          .ant-select-selection-item {
            opacity: 1;
          }
        }
        .ant-select-arrow {
          color: $primaryColor;
        }
      }
    }
    @extend %disabled;
  }
  &-item-option-active:not(.#{$prefix}-item-option-disabled) {
    background-color: $itemHoverBackground;
  }
}
