@import 'variables';

$prefix: $antPrefix + -layout;

.#{$prefix} {
  background: $bodyBackground;
  &-header,
  &-sider,
  &-sider-children,
  &-sider-trigger {
    background: $clientColor;
  }
}
